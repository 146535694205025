import React from 'react';
import { TextStorage, CalendarData } from '../../../utilities/types';
import PageSkeleton from '../../PageSkeleton/PageSkeleton';
import './SchedulePage.scss';
import calendarLogo from '../../../assets/calendar-logo.png';
import { Helmet } from 'react-helmet-async';
interface SchedulePageProps {
  ts: TextStorage;
}

const SchedulePage: React.FC<SchedulePageProps> = ({ ts }) => {

  const calendars: CalendarData[] = [
    {
      name: ts.menu.karate,
      googleUrl:
        'https://calendar.google.com/calendar/u/0?cid=ZGI5MHF1bGo4MHFzczdzdmk1MWtydGhmajhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      rawUrl:
        'https://calendar.google.com/calendar/embed?src=db90qulj80qss7svi51krthfj8%40group.calendar.google.com&ctz=Europe%2FBudapest5&mode=WEEK',
      imgSrc: calendarLogo,
    },
    {
      name: ts.menu.nia,
      googleUrl:
        'https://calendar.google.com/calendar/u/0?cid=ZzZub2M4aGZubnNrbm05NXF2MWczZjk3c2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      rawUrl:
        'https://calendar.google.com/calendar/embed?src=g6noc8hfnnsknm95qv1g3f97sc%40group.calendar.google.com&ctz=Europe%2FBudapest5&mode=WEEK',
      imgSrc: calendarLogo,
    },
    {
      name: ts.menu.ksg,
      googleUrl:
        'https://calendar.google.com/calendar/u/0?cid=ZGI5MHF1bGo4MHFzczdzdmk1MWtydGhmajhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      rawUrl:
        'https://calendar.google.com/calendar/embed?src=db90qulj80qss7svi51krthfj8%40group.calendar.google.com&ctz=Europe%2FBudapest5&mode=WEEK',
      imgSrc: calendarLogo,
    },
    {
      name: ts.menu.nipponZengo,
      googleUrl:
        'https://calendar.google.com/calendar/u/0?cid=ZzZub2M4aGZubnNrbm05NXF2MWczZjk3c2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      rawUrl:
        'https://calendar.google.com/calendar/embed?src=g6noc8hfnnsknm95qv1g3f97sc%40group.calendar.google.com&ctz=Europe%2FBudapest5&mode=WEEK',
      imgSrc: calendarLogo,
    },
    {
      name: ts.menu.nutritionCounceling,
      googleUrl:
        'https://calendar.google.com/calendar/u/0?cid=ZzZub2M4aGZubnNrbm05NXF2MWczZjk3c2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      rawUrl:
        'https://calendar.google.com/calendar/embed?src=g6noc8hfnnsknm95qv1g3f97sc%40group.calendar.google.com&ctz=Europe%2FBudapest5&mode=WEEK',
      imgSrc: calendarLogo,
    },
  ];

  return (
    <PageSkeleton bgColor="#151010">
      <Helmet>
        <title>Fudoshin Dojo | Különböző foglalkozásokhoz kapcsolódó naptárak</title>
        <meta name="description" content="A Fudoshin Dojo-ban tartott foglalkozások időpontjait tartalmazó naptárak elérhetőségei egy helyen. Jelentkezés a Kapcsolat menüpontban található információk alapján lehetséges." />
        <link rel="canonical" href="/schedule" />
        <meta
          name="keywords"
          content="schedule, órarend"
        />
      </Helmet>
      <div className="calendars-outer-box">
        <div className="page-title">{ts.schedule.title}</div>
        <div className="page-alternative-note">{ts.schedule.note}</div>
        <nav className="calendars-inner-box">
          {calendars.map((calendar) => {
            return (
              <div key={calendar.name} className="buttons-panel">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="calendar-button"
                  href={calendar.googleUrl}
                  tabIndex={0}
                >
                  <div className="calendar-name">{calendar.name}</div>
                  <img
                    height="60"
                    width="60"
                    src={calendar.imgSrc}
                    alt="calendar icon"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={calendar.rawUrl}
                  className="alt-calendar-button"
                >
                  {ts.schedule.altCalendarText}
                </a>
              </div>
            );
          })}
        </nav>
        <div className="page-alternative-note">
          {ts.schedule.alsoText}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="link"
            href={ts.footer.facebookLink}
          >
            {ts.schedule.facebookText}
          </a>
        </div>
      </div>
    </PageSkeleton>
  );
};

export default SchedulePage;
