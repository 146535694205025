import React, { useState } from "react";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import mapImg from "../../../assets/fudoshin-map.jpeg";
import dojoIcon from "../../../assets/dojoIcon.png";
import emailIcon from "../../../assets/email-logo.png";
import phoneIcon from "../../../assets/phone-logo.png";
import facebookIcon from "../../../assets/facebook-logo.png";
import "./ContactsPage.scss";
import classNames from "classnames";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import { Helmet } from "react-helmet-async";
interface ContactsPageProps {
    ts: TextStorage;
}

const ContactsPage: React.FC<ContactsPageProps> = ({ ts }) => {

    const [mapImageLoaded, setMapImageLoaded] = useState(false)
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Elérhetőségek, kapcsolat</title>
                <meta name="description" content="A Fudoshin Dojo-hoz tartozó edzőtermek helye, ill. a jelentkezéshez, egyeztetéshez vagy érdeklődéshez szükséges különböző elérhetőségek." />
                <link rel="canonical" href="/contacts" />
                <meta
                    name="keywords"
                    content="contacts, elérhetőségek, fudoshin, dojo"
                />
            </Helmet>
            <div className="contacts-outer-box">
                <div className="contacts-section">
                    <section className="contacts-section-title">{ts.contacts.phoneLabel}</section>
                    <section className="contacts-section-text">
                        <span className="contacts-with-icon">
                            <img width="18" src={phoneIcon} alt="phone" />
                            {ts.footer.phoneNumber}
                        </span>
                    </section>
                </div>
                <div className="contacts-section">
                    <section className="contacts-section-title">{ts.contacts.fbLabel}</section>
                    <section className="contacts-section-facebook-button">
                        <a
                            href="https://www.facebook.com/groups/253402304998328"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img height="20" src={facebookIcon} alt="facebook" />
                            {ts.mainPageTitle}
                        </a>
                    </section>
                </div>
                <div className="contacts-section">
                    <section className="contacts-section-title">{ts.contacts.emailLabel}</section>
                    <section className="contacts-section-text">
                        <a href="mailto:kyoapa@gmail.com">
                            <img width="24" src={emailIcon} alt="envelope" />
                            {ts.footer.email}
                        </a>
                    </section>
                </div>
                <div className="contacts-section">
                    <section className="contacts-section-title">{ts.contacts.addressLabel}</section>
                    <section className="contacts-section-text">
                        <section>{ts.contacts.addressLine1}</section>
                        <section>{ts.contacts.addressLine2}</section>
                        <section>{ts.contacts.addressLine3}</section>
                        <section>{ts.contacts.addressLine4}</section>
                    </section>
                </div>
                <div className="contacts-section-map">
                    {!mapImageLoaded && <LoadingPlaceholder width="349px" height="300px" borderRadius="12px" />}
                    <img className={classNames("contacts-map-img", { 'loaded': mapImageLoaded })} width="349" height="300" src={mapImg} alt="address on map" onLoad={() => setMapImageLoaded(true)} />
                    <a
                        href="https://www.google.com/maps/place/%C3%89rd,+Tan%C3%A1csos+u.+18,+2030/@47.3702527,18.9084513,146m/data=!3m1!1e3!4m5!3m4!1s0x4741e3e8961c08c5:0x4397225aa2651657!8m2!3d47.3702304!4d18.9088151"
                        className={classNames("contacts-map-pin", { 'loaded': mapImageLoaded })}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img className="contacts-map-pin-img" height="18" src={dojoIcon} alt="dojo" />
                    </a>
                </div>
            </div>
        </PageSkeleton>
    );
};

export default ContactsPage;
