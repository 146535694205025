import React, { useState } from "react";
import { TextStorage } from "../../../utilities/types";
import ContentSection from "../../ContentSection/ContentSection";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import SubpageSelector from "../../SubpageSelector/SubpageSelector";
import "./NiaPage.scss";
import NiaImgSrc from "../../../assets/niaDepiction.jpg";
import { Helmet } from "react-helmet-async";
interface NiaPageProps {
    ts: TextStorage;
}

const NiaPage: React.FC<NiaPageProps> = ({ ts }) => {
    const [selectedSubpageId, setSelectedSubpageId] = useState(Object.values(ts.nia.submenus)[0].id);
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | A Nia technika</title>
                <meta name="description" content="A Nia fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/nia" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, the nia technique, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <h1 className="introduction" >
                {ts.nia.pageTitle}
            </h1>
            <SubpageSelector subpages={Object.values(ts.nia.submenus)} selectedId={selectedSubpageId} handleSelection={(id: string) => setSelectedSubpageId(id)} />
            <div className="content-wrapper" >
                {selectedSubpageId === 'definition' &&
                    (<>
                        <ContentSection >
                            <section>{ts.nia.submenus.definition.text1}</section>
                            <div className="definition-section-image-wrapper">
                                <img src={NiaImgSrc} alt='Functional training practices' />
                            </div>
                            <section>{ts.nia.submenus.definition.text2}</section>
                        </ContentSection>
                    </>
                    )}
            </div>
        </PageSkeleton>
    );
};

export default NiaPage;
