import React, { useState } from "react";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./KaratePage.scss";
import SubpageSelector from "../../SubpageSelector/SubpageSelector";
import ContentSection from "../../ContentSection/ContentSection";
import DojoImgSrc from "../../../assets/dojoPic.jpg";
import KyokushinImgSrc from "../../../assets/kyokushinKaiKanji.jpg";
import { Helmet } from "react-helmet-async";

interface KaratePageProps {
  ts: TextStorage;
}

const KaratePage: React.FC<KaratePageProps> = ({ ts }) => {
  const [selectedSubpageId, setSelectedSubpageId] = useState(Object.values(ts.karate.submenus)[0].id);
  return (
    <PageSkeleton bgColor="#151010">
      <Helmet>
        <title>Fudoshin Dojo | Kyokushin Karate</title>
        <meta name="description" content="A Kyokushin Karate fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban az edzéseken a Fudoshin Dojo-ban mire lehet számítani." />
        <link rel="canonical" href="/karate" />
        <meta
          name="keywords"
          content="dojo, fitness, gym, karate, kyokushin, kyokushinkai, kyokushinkaikan, training, tréning, önfejlesztés, self-development, martial art, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
        />
      </Helmet>
      <h1 className="introduction" >
        {ts.karate.pageTitle}
      </h1>
      <SubpageSelector subpages={Object.values(ts.karate.submenus)} selectedId={selectedSubpageId} handleSelection={(id: string) => setSelectedSubpageId(id)} />
      <div className="content-wrapper" style={{ marginBottom: '300px' }}>
        {selectedSubpageId === 'karate' &&
          (<>
            <ContentSection >
              <section>{ts.karate.submenus.karate.text1}</section>
              <div className="karate-section-image-wrapper">
                <img src={DojoImgSrc} alt='Members of the dojo' />
              </div>
              <section>{ts.karate.submenus.karate.text2}</section>
            </ContentSection>
          </>
          )}

        {selectedSubpageId === 'kyokushin' &&
          (<>
            <ContentSection title={ts.karate.submenus.kyokushin.label}>
              <div className="kyokushin-section-image-wrapper">
                <img src={KyokushinImgSrc} alt='Kanji of kyokushin' />
                <section>{ts.karate.submenus.kyokushin.text}</section>
              </div>
            </ContentSection>
          </>
          )}

        {selectedSubpageId === 'dojoKun' &&
          (<ContentSection title={ts.karate.submenus.dojoKun.label}>
            <section>{ts.karate.submenus.dojoKun.description}</section>
            <ol style={{ paddingLeft: '16px', display: 'flex', flexDirection: 'column' }}>
              {Object.values(ts.karate.submenus.dojoKun.vows).map(vow => <li key={vow.slice(10, 15)} style={{ marginTop: '12px' }}>{vow}</li>)}
            </ol>
          </ContentSection>)}
      </div>
    </PageSkeleton>
  );
};

export default KaratePage;
