import React, { useEffect } from "react";
import classNames from "classnames";
import "./MainMenu.scss";
import { MainMenuItem } from "../../utilities/types";

interface MainMenuProps {
  openState: boolean;
  menuItems: MainMenuItem[];
  toggleMenuFunction: (isOpen: boolean) => void;
}

const MainMenu: React.FC<MainMenuProps> = ({ openState, menuItems, toggleMenuFunction }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        toggleMenuFunction(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleMenuFunction]);

  return (
    <div className="main-menu-outer-box">
      <div
        onClick={() => toggleMenuFunction(false)}
        className={classNames("main-menu-backdrop", { "open-state": openState })}
      />
      <nav
        className={classNames("main-menu-items-container", {
          "open-state": openState,
        })}
      >
        {menuItems.map((menuItem, index) => {
          return (
            <a
              key={menuItem.targetPageId}
              href={`/${menuItem.targetPageId}`}
              onClick={() => menuItem.clickFunction(menuItem.targetPageId)}
              className={classNames("main-menu-item", {
                active: menuItem.active,
              })}
              tabIndex={0}
            >
              {menuItem.targetPageName}
            </a>
          );
        })}
      </nav>
    </div>
  );
};

export default MainMenu;
