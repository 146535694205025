import React from "react";
import { Helmet } from "react-helmet-async";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./KSGPage.scss";
interface KSGPageProps {
    ts: TextStorage;
}

const KSGPage: React.FC<KSGPageProps> = ({ ts }) => {
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Kid's Smart Gymnastics</title>
                <meta name="description" content="A KSG fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/ksg" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, kid's smart gymnastics, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <div>{ts.menu.ksg}</div>
        </PageSkeleton>
    );
};

export default KSGPage;
