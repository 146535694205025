import React from "react";
import "./HomePage.scss";
import NavCard from "../../NavCard/NavCard";
import karateImg from "../../../assets/karateBeta.png";
import ksgImg from "../../../assets/KSG.png";
import funkcionalisImg from "../../../assets/funkcionalis_edzes.png";
import { TextStorage } from "../../../utilities/types";
import { Helmet } from "react-helmet-async";

interface HomePageProps {
    ts: TextStorage;
}

const HomePage: React.FC<HomePageProps> = ({ ts }) => {
    return (
        <div className="homepage-outer-box">
            <Helmet>
                <title>Fudoshin Dojo | Üdvözlünk a dojoban</title>
                <meta name="description" content="Karate és Mozgásfejlesztő SE, Érd | Test és lélek fejlesztése a lelki egyensúlyért és harmóniáért. A legnagyobb győzelem önmagunk legyőzése." />
                <link rel="canonical" href="/home" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, nippon zengo, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <h1 className="welcome-text">{ts.welcomeText}</h1>
            <nav className="trainings-container">
                <NavCard pageId="karate" img={karateImg} title={ts.menu.karate} />
                <NavCard
                    pageId="functional-training"
                    img={funkcionalisImg}
                    title={ts.menu.functionalTraining}
                />
                <NavCard pageId="ksg" img={ksgImg} title={ts.menu.ksg} />
                <NavCard pageId="nia" img={karateImg} title={ts.menu.nia} />
                <NavCard pageId="nippon-zengo" img={karateImg} title={ts.menu.nipponZengo} />
                <NavCard
                    pageId="nutrition-counceling"
                    img={karateImg}
                    title={ts.menu.nutritionCounceling}

                />
                <NavCard pageId="stature-training" img={karateImg} title={ts.menu.statureTraining} />
                <NavCard
                    pageId="sport-rehabilitation"
                    img={karateImg}
                    title={ts.menu.sportRehabilitation}

                />
            </nav>
        </div>
    );
};

export default HomePage;
