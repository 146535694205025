import React from "react";
import { Helmet } from "react-helmet-async";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./GalleryPage.scss";
interface GalleryPageProps {
    ts: TextStorage;
}

const GalleryPage: React.FC<GalleryPageProps> = ({ ts }) => {
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Galéria</title>
                <meta name="description" content="A táborok, edzések és mindennapok képeihez vezető linkek." />
                <link rel="canonical" href="/gallery" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, gallery, galária, képek, pictures, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <div>{ts.menu.gallery}</div>
        </PageSkeleton>
    );
};

export default GalleryPage;
