import React from 'react';
import './PageSkeleton.scss';

interface PageSkeletonProps {
  children: React.ReactNode;
  bgColor: string;
}

const PageSkeleton: React.FC<PageSkeletonProps> = ({ children, bgColor }) => {
  return (
    <div style={{ backgroundColor: bgColor }} className="page-content-box">
      {children}
    </div>
  );
};

export default PageSkeleton;
