import React, { useState, useCallback } from "react";
import "./Header.scss";
import HamburgerMenuButton from "../HamburgerMenuButton/HamburgerMenuButton";
import MainMenu from "../MainMenu/MainMenu";
import { MainMenuItem, TextStorage } from "../../utilities/types";
import textStorage from "../../utilities/multilangTextStorage";
import hunFlag from "../../assets/hunFlag.png";
import engFlag from "../../assets/engFlag.png";
import classNames from "classnames";
import { useLocation, useHistory } from "react-router-dom";

interface HeaderProps {
  textStorageSetter: (selectedTextStorage: TextStorage) => void;
  ts: TextStorage;
}

const Header: React.FC<HeaderProps> = ({ textStorageSetter, ts }) => {
  const location = useLocation();
  const history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onSelectPage = (pageId: string) => {
    toggleMobileMenu(false);
    history.push(pageId);
  };

  const toggleMobileMenu = useCallback((isOpen: boolean) => {
    setMobileMenuOpen(isOpen);
    console.log(isOpen);
  }, []);

  const mainMenuItems: MainMenuItem[] = [
    {
      targetPageId: "home",
      targetPageName: ts.menu.home,
      clickFunction: onSelectPage,
      active: location.pathname === "/home",
    },
    {
      targetPageId: "schedule",
      targetPageName: ts.menu.schedule,
      clickFunction: onSelectPage,
      active: location.pathname === "/schedule",
    },
    {
      targetPageId: "contacts",
      targetPageName: ts.menu.contacts,
      clickFunction: onSelectPage,
      active: location.pathname === "/contacts",
    },
    {
      targetPageId: "gallery",
      targetPageName: ts.menu.gallery,
      clickFunction: onSelectPage,
      active: location.pathname === "/gallery",
    },
    {
      targetPageId: "karate",
      targetPageName: ts.menu.karate,
      clickFunction: onSelectPage,
      active: location.pathname === "/karate",
    },
    {
      targetPageId: "functional-training",
      targetPageName: ts.menu.functionalTraining,
      clickFunction: onSelectPage,
      active: location.pathname === "/functional-training",
    },
    {
      targetPageId: "ksg",
      targetPageName: ts.menu.ksg,
      clickFunction: onSelectPage,
      active: location.pathname === "/ksg",
    },
    {
      targetPageId: "nia",
      targetPageName: ts.menu.nia,
      clickFunction: onSelectPage,
      active: location.pathname === "/nia",
    },
    {
      targetPageId: "nippon-zengo",
      targetPageName: ts.menu.nipponZengo,
      clickFunction: onSelectPage,
      active: location.pathname === "/nippon-zengo",
    },
    {
      targetPageId: "nutrition-counceling",
      targetPageName: ts.menu.nutritionCounceling,
      clickFunction: onSelectPage,
      active: location.pathname === "/nutrition-counceling",
    },
    {
      targetPageId: "stature-training",
      targetPageName: ts.menu.statureTraining,
      clickFunction: onSelectPage,
      active: location.pathname === "/stature-training",
    },
    {
      targetPageId: "sport-rehabilitation",
      targetPageName: ts.menu.sportRehabilitation,
      clickFunction: onSelectPage,
      active: location.pathname === "/sport-rehabilitation",
    },
  ];

  const getSelectedPage = (): MainMenuItem | undefined => {
    return mainMenuItems.find((item) => item.targetPageId === (location.pathname.replace("/", "") || "home"));
  };

  return (
    <div className="header-wrapper">
      <div className="header-bar-box">
        <HamburgerMenuButton toggleMenu={() => toggleMobileMenu(!mobileMenuOpen)} open={mobileMenuOpen} />
        <MainMenu menuItems={mainMenuItems} openState={mobileMenuOpen} toggleMenuFunction={toggleMobileMenu} />
        <div className="page-info-bar">
          <div
            className={classNames("flag-icon-button", {
              selected: ts.id === "hun",
            })}
            onClick={() => textStorageSetter(textStorage.hungarian)}
          >
            <img src={hunFlag} alt="hungarian flag" height="16" />
          </div>
          <div
            className={classNames("flag-icon-button", {
              selected: ts.id === "eng",
            })}
            onClick={() => textStorageSetter(textStorage.english)}
          >
            <img src={engFlag} alt="united kingdom flag" height="16" />
          </div>
          <div className="selected-page-title">{getSelectedPage()?.targetPageName}</div>
        </div>

        <div className="title-text" onClick={() => onSelectPage("home")}>
          {ts.mainPageTitle}
        </div>
      </div>
    </div>
  );
};

export default Header;
