import React from "react";
import './ContentSection.scss';

interface ContentSectionProps {
  title?: string;
  imageSrc?: string;
  imageHeight?: number;
  imageFirst?: boolean;
}

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  imageSrc,
  imageHeight,
  imageFirst,
  children,
}) => {
  return (
    <div className="content-section-outer-box">
      {title && <h2>{title}</h2>}
      <div className="content-box">
        {imageSrc && imageFirst && <div className="img-box"><img src={imageSrc} alt={title} height={imageHeight} /></div>}
        {children}
        {imageSrc && !imageFirst && <img src={imageSrc} alt={title} height={imageHeight} />}
      </div>
    </div>
  );
};

export default ContentSection;
