import MainBackground from './components/MainBackground/MainBackground';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import { useEffect } from 'react';
import './App.scss';

const App = () => {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Canela Bold'],
      },
    });
  }, []);
  return (
    <BrowserRouter>
      <MainBackground />
    </BrowserRouter>
  );
};

export default App;
