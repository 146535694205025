import { TextStorage } from "./types";

export const textStorage: { [key: string]: TextStorage } = {
  english: {
    id: "eng",
    mainPageTitle: "Fudoshin Dojo",
    welcomeText: "Welcome to the dojo",
    menu: {
      home: "Home",
      schedule: "Schedule",
      contacts: "Contacts",
      gallery: "Gallery",
      karate: "Karate",
      functionalTraining: "Functional training",
      ksg: "KSG",
      nia: "Nia",
      nipponZengo: "Nippon Zengo",
      nutritionCounceling: "Nutrition counceling",
      statureTraining: "Stature training",
      sportRehabilitation: "Sport rehabilitation",
    },
    footer: {
      email: "kyoapa@gmail.com",
      phoneNumber: "+36309349410",
      facebookLink: "https://www.facebook.com/groups/253402304998328",
      copiedText: "copied!",
      failedToCopyText: "failed to copy :(",
    },
    slogan: "The first and greatest victory is to conquer yourself",
    schedule: {
      title: "Select the activity you are interested in, to see the relevant schedule.",
      note: "To view a calendar without a Google account, click on the 'Alternative view' button.",
      altCalendarText: "Alternative view",
      alsoText: "You can also find up do date information about the time and place of trainings in the ",
      facebookText: "Fudoshin Dojo Facebook group.",
    },
    contacts: {
      phoneLabel: "Phone",
      fbLabel: "Facebook group",
      emailLabel: "E-mail",
      addressLabel: "Dojo Location",
      addressLine1: "Magyarország",
      addressLine2: "Érd",
      addressLine3: "Tanácsos street 18",
      addressLine4: "2030",
    },
    karate: {
      pageTitle: "Kyokushin Karate",
      submenus: {
        karate: {
          id: "karate",
          label: "Karate",
          text1: "Karate (空手) is a martial art. The literal meaning of the word karate is 'empty hand', and the expression Karate-do means Way of the Empty Hand. As the name implies, karate is a martial art that revolves around using only your own body in combat, and no weapons. It's aim is not victory in combat, but perfecting one's mind and body, and develop one's character. It is more than mere sport. It is a way of life, a perspective, a spiritual journey through martial art.",
          text2: "In the Fudoshin Dojo kyokushin karate trainings are held regularly, lead by the head of the dojo, sensei Gabor Kerekes (III. dan), or one of the sempais (higher ranking members) of the dojo. Anyone interested in joining the training is welcome to reach out, via one of the options presented in the Contacts page.",
        },
        kyokushin: {
          id: "kyokushin",
          label: "Kyokushin",
          text: "The meaning of Kyokushin karate is 'Karate of The Ultimate Truth'. The expression Kyokushinkai is often used as well, which means 'Meeting with The Ultimate Truth' or 'Society of The Ultimate Truth', depending on the interpretation. Kyokushin is rooted in a philosophy that only through self-improvement, discipline, and hard training can we find the ultimate truth. It includes the straight striking, blocking, and kicking techniques of traditional japanese karate, but also includes the circular techniques of chinese kempo. The saying 'Every effective karate technique must be the consequence of a preceeding hip rotation!' is often repeated during training. Via this technique it is possible to focus all of the body's energy into one point of contact.",
        },
        dojoKun: {
          id: "dojoKun",
          label: "Dojo Kun",
          description: "The Dojo Kun is the vow of the dojo. It's seven points encapsulate the wisdom, attitude, moral values and behavioral patterns necessary to practice karate.",
          vows: {
            one: "We unyieldingly train our hearts and bodies.",
            two: "We hone our skills as we pursue the true meaning of the Budo Way.",
            three: "With true vigor, we will seek to conquer ourselves.",
            four: "We follow the rules of courtesy, respect our superiors, and refrain from violence.",
            five: "We respect the gods and buddhas, and do not forget the virtue of humility.",
            six: "We must improve our intellect and physical strength, and not make mistakes in our work.",
            seven: "We must practice lifelong training in the way of karate and fulfill the way of kyokushin.",
          }
        },
      }
    },
    functionalTraining: {
      pageTitle: "Functional training",
      submenus: {
        definition: {
          id: "definition",
          label: "Definition",
          text1: "Functional training is a term used to describe exercises that help you perform activities in everyday life more easily. These exercises typically utilize multiple muscles and emphasize core strength and stability."
        }
      }
    },
    nia: {
      pageTitle: "Nia",
      submenus: {
        definition: {
          id: "definition",
          label: "Definition",
          text1: "The name Nia  is an abbreviation of Neuromuscular Integrative Action, which means bringing the nervous system, the respiratory system, and the circulatory system into harmony with each other through movement.",
          text2: "This holistic approach to fitness comes from the United States, created by Debbie and Carlos Rosas, who assembled a moveset that merges elements from eastern martial arts such as tai-chi, taekwondo, aikido and also from yoga and western dance styles, while focusing on therapeutic methods such as the Alexander technique and the Feldenkrais method."
        }
      }
    },
  },
  hungarian: {
    id: "hun",
    mainPageTitle: "Fudoshin Dojo",
    welcomeText: "Üdvözlünk a dojoban",
    menu: {
      home: "Főoldal",
      schedule: "Edzésrend",
      contacts: "Kapcsolat",
      gallery: "Galéria",
      karate: "Karate",
      functionalTraining: "Funkcionális edzés",
      ksg: "KSG",
      nia: "Nia",
      nipponZengo: "Nippon Zengo",
      nutritionCounceling: "Táplálkozási tanácsadás",
      statureTraining: "Tartásjavító torna",
      sportRehabilitation: "Sport rehabilitáció",
    },
    footer: {
      email: "kyoapa@gmail.com",
      phoneNumber: "+36309349410",
      facebookLink: "https://www.facebook.com/groups/253402304998328",
      copiedText: "vágólapra másolva!",
      failedToCopyText: "nem sikerült vágólapra tenni :(",
    },
    slogan: "A legnagyobb győzelem az önmagunk felett aratott győzelem",
    schedule: {
      title: "Válaszd ki azt a foglalkozást, melynek az időpontjaira kíváncsi vagy.",
      note: "Ha Google fiók nélkül szeretnél hozzáférni az adott naptárhoz, kattints az 'Alternatív nézet' gombra.",
      altCalendarText: "Alternatív nézet",
      alsoText: "Találhatsz még friss információt az edzések helyéről és idejéről a ",
      facebookText: "Fudoshin Dojo Facebook csoportjában.",
    },
    contacts: {
      phoneLabel: "Telefon",
      fbLabel: "Facebook csoport",
      emailLabel: "E-mail",
      addressLabel: "Dojo címe",
      addressLine1: "Magyarország",
      addressLine2: "Érd",
      addressLine3: "Tanácsos utca 18",
      addressLine4: "2030",
    },
    karate: {
      pageTitle: "Kyokushin Karate",
      submenus: {
        karate: {
          id: "karate",
          label: "Karate",
          text1: "A Karate (空手) egy szabadkezes japán harcművészet. A karate jelentése szó szerint: üres kéz, vagyis olyan fegyver nélküli harcművészet, melynek művelője kizárólag saját testrészeit használja küzdelemre. Célja nem a győzelem, hanem a gyakorlatok tökéletesítése és a jellem fejlesztése. Több, mint sport vagy egyszerű testmozgás. Egy életmód, egy szemlélet, a harcművészeten keresztül gyakorolt szellemi út.",
          text2: "A Fudoshin Dojo-ban rendszeresen tart kyokushin karate edzéseket a dojo vezetője, Kerekes Gábor (III. dan), vagy egy rangidős sempai. Aki csatlakozna az edzéshez, a kapcsolat menüpontban megtalálható elérhetőségek egyikén tud érdeklődni.",
        },
        kyokushin: {
          id: "kyokushin",
          label: "Kyokushin",
          text: "A Kyokushin karate jelentése: a 'Végső Igazság Karatéja'. Használjuk még Kyokushinkai szóösszetételben, ahol a Kai szó a találkozást jelenti. Ilyen formában tehát: 'Találkozás a Végső Igazsággal'. A Kyokushin szerint önfejlesztésen, fegyelemen, és kíméletlen testedzésen keresztül vezet az út a végső igazsághoz. Technikái között megtalálhatjuk a tradicionális japán karate egyenes vonalú ütéseit, védéseit, rúgásait, azonban amiben teljesen új reformot hozott a tradicionális iskolákkal szemben, az a kínai kempo körkörös technikáinak alkalmazása. 'Minden igazi, nagyerejű és hatékony karatetechnika a csípőmozgás következménye kell, hogy legyen!'. Ezen elven keresztül már lehetséges a test összes energiaforrását mozgósítani és összpontosítani a találati pontba.",
        },
        dojoKun: {
          id: "dojoKun",
          label: "Dojo Kun",
          description: "A Dojo Kun az edzőterem esküje. A hét pontjában megtalálható mindaz a karatéhoz szükséges bölcsesség, hozzáállás, erkölcsi érték és alapvető viselkedésforma, ami nélkül nem képzelhető el ez az oktatási rendszer. A karate csak ezen elvek szigorú alkalmazása által lehet erős és egyben igazságos.",
          vows: {
            one: "Fogadjuk: Rendületlenül eddzük testünket és lelkünket.",
            two: "Fogadjuk: A Budó igaz útját követjük, így fejlesztjük képességeinket.",
            three: "Fogadjuk: Szilárdan törekszünk önmagunk legyőzésére.",
            four: "Fogadjuk: Megtartjuk az udvariasság szabályait, tiszteljük elöljáróinkat és a rangban feljebb levőket. Tartózkodunk az erőszaktól.",
            five: "Fogadjuk: Követjük az emberiesség tanítását, soha nem feledjük az alázatosság igaz erényét.",
            six: "Fogadjuk: Tiszteljük a bölcsességet és az erőt, nem keresünk más vágyakat.",
            seven: "Fogadjuk: Egész életünkben a karate fegyelmével járjuk a Kyokushin útját.",
          }
        },
      }
    },
    functionalTraining: {
      pageTitle: "Funkcionális edzés",
      submenus: {
        definition: {
          id: "definition",
          label: "Fogalma",
          text1: "A funkcionális tréning lényege, hogy olyan gyakorlatokat végzünk, amelyek a mindennapi életben is megállják a helyüket, melyek során a testünk és izmaink a maguk természetes módján mozognak. Nem izoláltan eddzük az egyes izomcsoportokat, hanem egy egységes egészként tekintünk a testre."
        }
      }
    },
    nia: {
      pageTitle: "Nia",
      submenus: {
        definition: {
          id: "definition",
          label: "Fogalma",
          text1: "A Nia Technika (Neuromuscular Integrative Action), a keleti hangzású neve valójában rövidítés, melynek jelentése úgy foglalható össze: az idegrendszer, a légző- és keringési rendszer mozgással történő harmonizálása.",
          text2: "Ez a holisztikus fitneszirányzat az Egyesült Államokból származik, megalkotói Debbie és Carlos Rosas, akik egy olyan mozgásvilágot hoztak létre, mely harmonikusan ötvözi a keleti harcművészetekből átvett taj-csi-, taekwondo-, aikidoelemeket, a jógapozíciókat a Nyugat táncművészetének stílusaival (modern tánc, Duncan dance, jazztánc, funky), illetve a terápiás módszerek közül az Alexander-technikával és a Feldenkrais-módszerrel."
        }
      }
    },
  },
};

export default textStorage;
