import React from "react";
import { Helmet } from "react-helmet-async";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./NipponZengoPage.scss";
interface NipponZengoPageProps {
    ts: TextStorage;
}

const NipponZengoPage: React.FC<NipponZengoPageProps> = ({ ts }) => {
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Nippon Zengo</title>
                <meta name="description" content="A Nippon Zengo fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/nippon-zengo" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, nippon zengo, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <div>{ts.menu.nipponZengo}</div>
        </PageSkeleton>
    );
};

export default NipponZengoPage;
