import React from "react";
import "./Footer.scss";
import ContactsWidget from "../ContactsWidget/ContactsWidget";
import faceLogo from "../../assets/facebook-logo.png";
import phoneLogo from "../../assets/phone-logo.png";
import emailLogo from "../../assets/email-logo.png";
import { TextStorage } from "../../utilities/types";

interface FooterProps {
    ts: TextStorage;
}

const Footer: React.FC<FooterProps> = ({ ts }) => {
    return (
        <>
            <div className="fudoshin-footer">
                <div className="footer-content">
                    <div className="widget-box">
                        <ContactsWidget url={ts.footer.facebookLink} img={faceLogo} altText="Facebook logo" />
                    </div>
                    <div className="widget-box">
                        <ContactsWidget
                            text={ts.footer.phoneNumber}
                            img={phoneLogo}
                            altText="Phone number logo"
                            copiedText={ts.footer.copiedText}
                            failedToCopyText={ts.footer.failedToCopyText}
                        />
                    </div>
                    <div className="widget-box">
                        <ContactsWidget
                            text={ts.footer.email}
                            img={emailLogo}
                            altText="Email logo"
                            copiedText={ts.footer.copiedText}
                            failedToCopyText={ts.footer.failedToCopyText}
                        />
                    </div>
                </div>
                <div className="slogan">{ts.slogan}</div>
            </div>
        </>
    );
};

export default Footer;
