import React, { useState } from "react";
import { TextStorage } from "../../../utilities/types";
import ContentSection from "../../ContentSection/ContentSection";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import SubpageSelector from "../../SubpageSelector/SubpageSelector";
import "./FunctionalTrainingPage.scss";
import FunctionalTrainingImgSrc from "../../../assets/functionalTrainingDepiction.png";
import { Helmet } from "react-helmet-async";
interface FunctionalTrainingPageProps {
    ts: TextStorage;
}

const FunctionalTrainingPage: React.FC<FunctionalTrainingPageProps> = ({ ts }) => {
    const [selectedSubpageId, setSelectedSubpageId] = useState(Object.values(ts.functionalTraining.submenus)[0].id);
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Funkcionális edzés</title>
                <meta name="description" content="A funkcionális edzés fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/functional-training" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, functional training, funkcionális, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <h1 className="introduction" >
                {ts.functionalTraining.pageTitle}
            </h1>
            <SubpageSelector subpages={Object.values(ts.functionalTraining.submenus)} selectedId={selectedSubpageId} handleSelection={(id: string) => setSelectedSubpageId(id)} />
            <div className="content-wrapper" >
                {selectedSubpageId === 'definition' &&
                    (<>
                        <ContentSection >
                            <div className="definition-section-image-wrapper">
                                <img src={FunctionalTrainingImgSrc} alt='Functional training practices' />
                            </div>
                            <section>{ts.functionalTraining.submenus.definition.text1}</section>
                        </ContentSection>
                    </>
                    )}
            </div>
        </PageSkeleton>
    );
};

export default FunctionalTrainingPage;
