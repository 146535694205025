import React, { useState } from 'react';
import classNames from 'classnames';
import LoadingPlaceholder from '../pages/LoadingPlaceholder/LoadingPlaceholder';
import './NavCard.scss';

interface NavCardProps {
  img: string;
  title: string;
  pageId: string;
}

const NavCard: React.FC<NavCardProps> = ({
  img,
  title,
  pageId,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <a className="card-outer-box" href={`/${pageId}`}>
      {!imageLoaded && <LoadingPlaceholder width="150px" height="140px" borderRadius="12px" />}
      <div className={classNames("card-illustration", { 'loaded': imageLoaded })} >
        <img style={{ color: 'white' }} src={img} alt={title} title={title} height="140" onLoad={() => setImageLoaded(true)} />
      </div>
      <div className="card-title">{title}</div>
    </a>
  );
};

export default NavCard;
