import React from "react";
import { Helmet } from "react-helmet-async";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./SportRehabilitationPage.scss";
interface SportRehabilitationPageProps {
    ts: TextStorage;
}

const SportRehabilitationPage: React.FC<SportRehabilitationPageProps> = ({ ts }) => {
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Sport-rehabilitáció</title>
                <meta name="description" content="A sport-rehabilitáció fogalma, hogy mit jelent számunkra, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/sport-rahabilitation" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, sport rehabilitation, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <div>{ts.menu.sportRehabilitation}</div>
        </PageSkeleton>
    );
};

export default SportRehabilitationPage;
