import React from "react";
import classNames from "classnames";
import './SubpageSelector.scss';

interface SubpageSelectorProps {
  subpages: {
    id: string;
    label: string;
  }[];
  selectedId: string;
  handleSelection: (subpageId: string) => void;
}

const SubpageSelector: React.FC<SubpageSelectorProps> = ({ subpages, selectedId, handleSelection }) => {
  return (
    <div className="subpage-selection-outer-box">
      {subpages.map((page) => (
        <button className={classNames("subpage-option", { 'selected': selectedId === page.id })} key={page.id} onClick={() => handleSelection(page.id)}>
          {page.label}
        </button>
      ))}
    </div>
  );
};

export default SubpageSelector;
