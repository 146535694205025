import React from "react";
import { Helmet } from "react-helmet-async";
import { TextStorage } from "../../../utilities/types";
import PageSkeleton from "../../PageSkeleton/PageSkeleton";
import "./StatureTrainingPage.scss";
interface StatureTrainingPageProps {
    ts: TextStorage;
}

const StatureTrainingPage: React.FC<StatureTrainingPageProps> = ({ ts }) => {
    return (
        <PageSkeleton bgColor="#151010">
            <Helmet>
                <title>Fudoshin Dojo | Tartásjavító torna</title>
                <meta name="description" content="A tartásjavító torna lényege, haszna, hogy mikor érdemes elkezdeni, és hogy a gyakorlatban a foglalkozásokon a Fudoshin Dojo-ban mire lehet számítani." />
                <link rel="canonical" href="/stature-training" />
                <meta
                    name="keywords"
                    content="dojo, fitness, gym, nia, stature training, tartásjavító torna, training, tréning, önfejlesztés, self-development, érd, Érd, Tárnok, mozgás, edzés, testnevelés"
                />
            </Helmet>
            <div>{ts.menu.statureTraining}</div>
        </PageSkeleton>
    );
};

export default StatureTrainingPage;
