import React, { useState } from "react";
import "./ContactsWidget.scss";
interface ContactsWidgetProps {
    img: string;
    altText: string;
    url?: string;
    text?: string;
    copiedText?: string;
    failedToCopyText?: string;
}

const ContactsWidget: React.FC<ContactsWidgetProps> = ({ img, altText, url, text, copiedText, failedToCopyText }) => {
    const [currentText, setCurrentText] = useState(text);

    const onContactClick = () => {
        if (text) {
            navigator.clipboard.writeText(text).then(
                function () {
                    setCurrentText(copiedText);
                },
                function (err) {
                    setCurrentText(failedToCopyText);
                }
            );
            setTimeout(() => {
                setCurrentText(text);
            }, 1000);
        }
    };

    return (
        <div>
            {url && (
                <a href={url} className="contacts-img-container hoverscale">
                    <img src={img} alt={altText} height="25" />
                </a>
            )}
            {text && (
                <div className="contacts-img-container expandable-widget">
                    <div onClick={onContactClick} className="widget-image">
                        <img src={img} alt={altText} height="25" />
                    </div>
                    <div onClick={onContactClick} className="widget-text">
                        {currentText}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactsWidget;
