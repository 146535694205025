import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./MainBackground.scss";
import HomePage from "../pages/HomePage/HomePage";
import SchedulePage from "../pages/SchedulePage/SchedulePage";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import FunctionalTrainingPage from "../pages/FunctionalTrainingPage/FunctionalTrainingPage";
import GalleryPage from "../pages/GalleryPage/GalleryPage";
import KSGPage from "../pages/KSGPage/KSGPage";
import NiaPage from "../pages/NiaPage/NiaPage";
import NipponZengoPage from "../pages/NipponZengoPage/NipponZengoPage";
import NutritionCouncelingPage from "../pages/NutritionCouncelingPage/NutritionCouncelingPage";
import SportRehabilitationPage from "../pages/SportRehabilitationPage/SportRehabilitationPage";
import StatureTrainingPage from "../pages/StatureTrainingPage/StatureTrainingPage";
import KaratePage from "../pages/KaratePage/KaratePage";
import textStorage from "../../utilities/multilangTextStorage";
import { TextStorage } from "../../utilities/types";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCookie } from "../../utilities/functions";

const MainBackground: React.FC = () => {
  const [selectedTextStorage, setSelectedTextStorage] = useState<TextStorage>(textStorage.hungarian);

  useEffect(() => {
    const relevantTextStorage = Object.values(textStorage).find(tsObj => tsObj.id === getCookie("selectedLanguage"));
    if (relevantTextStorage) {
      setSelectedTextStorage(relevantTextStorage);
    }
  }, []);

  const handleLanguageSelection = (selectedLanguage: TextStorage) => {
    setSelectedTextStorage(selectedLanguage);
    document.cookie = `selectedLanguage=${selectedLanguage.id}; expires=${new Date(2147483647 * 1000).toUTCString()}`;
  }

  return (
    <BrowserRouter>
      <div className="main-background">
        <div className="main-content-wrapper">
          <Switch>
            <Route path="/home">
              <HomePage ts={selectedTextStorage} />
            </Route>
            <Route path="/schedule">
              <SchedulePage ts={selectedTextStorage} />
            </Route>
            <Route path="/contacts">
              <ContactsPage ts={selectedTextStorage} />
            </Route>
            <Route path="/gallery">
              <GalleryPage ts={selectedTextStorage} />
            </Route>
            <Route path="/karate">
              <KaratePage ts={selectedTextStorage} />
            </Route>
            <Route path="/functional-training">
              <FunctionalTrainingPage ts={selectedTextStorage} />
            </Route>
            <Route path="/ksg">
              <KSGPage ts={selectedTextStorage} />
            </Route>
            <Route path="/nia">
              <NiaPage ts={selectedTextStorage} />
            </Route>
            <Route path="/nippon-zengo">
              <NipponZengoPage ts={selectedTextStorage} />
            </Route>
            <Route path="/nutrition-counceling">
              <NutritionCouncelingPage ts={selectedTextStorage} />
            </Route>
            <Route path="/stature-training">
              <StatureTrainingPage ts={selectedTextStorage} />
            </Route>
            <Route path="/sport-rehabilitation">
              <SportRehabilitationPage ts={selectedTextStorage} />
            </Route>
            <Route path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
          <Footer ts={selectedTextStorage} />
        </div>
        <Header ts={selectedTextStorage} textStorageSetter={handleLanguageSelection} />
      </div>
    </BrowserRouter>
  );
};

export default MainBackground;
