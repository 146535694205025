import React from 'react';
import './LoadingPlaceholder.scss';

interface LoadingPlaceholderProps {
    width?: string;
    height?: string;
    borderRadius?: string;
}

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ height, width, borderRadius }) => {
    return (
        <div className="placeholder-wrapper">
            <div style={{ borderRadius: borderRadius ?? '4px' }} className="placeholder">
                <div style={{ width: width ?? '100%', height: height ?? '100%', borderRadius: borderRadius ?? '4px' }} className="animated-background"></div>
            </div>
        </div>
    );
}

export default LoadingPlaceholder;